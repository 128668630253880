<div class='app-navbar'>
  <div class='app-navbar__logo'>
    <img alt="logo" class='app-navbar__logo-img' src="../../../../assets/images/tieple_logo.png"/>
    <div class='app-navbar__logo-name'>tieple</div>
  </div>
  <div class='app-navbar__right-menu'>
    <span class='app-navbar__menu'>Why Tieple?</span>
    <span class='app-navbar__menu'>Courses</span>
    <span class='app-navbar__menu'>Mentorship</span>
    <span class='app-navbar__menu'>For Companies</span>
    <span class='app-navbar__menu'>For Candidates/Students</span>
    <span class='app-navbar__menu'>More</span>
  </div>
  <!-- <mat-icon>menu</mat-icon> -->
</div>
