export const socialHandles: { key: number, title: string, link: string }[] = [
  {
    key: 1,
    title: 'LinkedIn',
    link: 'https://www.linkedin.com'
  },
  {
    key: 2,
    title: 'Facebook',
    link: 'https://www.fb.com'
  },
  {
    key: 3,
    title: 'Twitter',
    link: 'https://www.twitter.com'
  },
  {
    key: 4,
    title: 'Instagram',
    link: 'https://www.instagram.com'
  },
  {
    key: 5,
    title: 'Youtube',
    link: 'https://www.youtube.com'
  }
];
