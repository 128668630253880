import {NgModule} from '@angular/core';
import {IvNxtRoutes, VNxtCanActivateGuard} from "@vnxt/angular-auth";
import {RouterModule} from '@angular/router';
import {ContentLayoutComponent} from '@layout/public/content-layout/content-layout.component';

const routes: IvNxtRoutes = [
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [VNxtCanActivateGuard], // Should be replaced with actual auth guard,
    data: {
      Title: 'Title',
      Breadcrumb: 'Title',
      accessPath: []
    },
    children: [
      {
        // Fallback when no prior routes is matched
        path: '**',
        loadChildren: () =>
          import('@modules/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'about',
        loadChildren: () =>
          import('@modules/about/about.module').then(m => m.AboutModule)
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('@modules/contact/contact.module').then(m => m.ContactModule)
      }
    ]
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
