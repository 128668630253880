import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SpinnerComponent} from './component/spinner/spinner.component';
import {AngularSupportModule} from "@vnxt/angular-support";
import {ISupportEnvironmentOptions} from "@vnxt/angular-support/lib/interface/common";
import {environment} from "../../../environments/environment";

@NgModule({
  declarations: [SpinnerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AngularSupportModule.initializeAppModule(environment as ISupportEnvironmentOptions),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SpinnerComponent,
    AngularSupportModule
  ]
})
export class SharedModule {
}
