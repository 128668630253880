import {Component, OnInit} from '@angular/core';
import {VNxtRouteService} from "@vnxt/angular-auth";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private routerService: VNxtRouteService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  async ngOnInit() {
    await this.routerService.makeBreadcrumTreeRecursive(this.activatedRoute)
  }
}
