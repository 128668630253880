<div class="app-footer__section">
  <div class="app-footer__section-tieple">
    <div class="app-footer__section-header">TiePLE</div>
    <div *ngFor="let item of tiepleLinks" class="app-footer__section-options">
      <a class="app-footer__section-option" href="#" target="_blank">{{item}}</a>
    </div>

  </div>
  <div class="app-footer__section-important">
    <div class="app-footer__section-header">Important</div>
    <div *ngFor="let item of important" class="app-footer__section-options">
      <a class="app-footer__section-option" href="#" target="_blank">{{item}}</a>
    </div>

  </div>
  <div class="app-footer__section-connect">
    <div class="app-footer__section-header">Stay Connected</div>
    <div *ngFor="let item of stayConnected" class="app-footer__section-options">
      <a [href]="item.link" class="app-footer__section-option" target="_blank">{{item.title}}</a>
    </div>
  </div>
</div>
