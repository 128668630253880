import {Component} from '@angular/core';
import {socialHandles} from '@constants/socialHandles';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
  public stayConnected: { key: number, title: string, link: string }[] = socialHandles;
  public important: string[] = ['Exam Detail', 'Section Criteria'];
  public tiepleLinks: string[] = ['About Us', 'Contact Us', 'Course Offerings', 'FAQs', 'Terms and Conditions', 'Privacy Policy', 'Security Fraud', 'Send Feedback']

  constructor() {
  }

  ngOnInit(): void {
  }
}
