import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {ContentLayoutComponent} from '@layout/public/content-layout/content-layout.component';
import {NavComponent} from '@layout/public/nav/nav.component';
import {FooterComponent} from '@layout/public/footer/footer.component';
import {SharedModule} from '@shared/shared.module';
import {NgxYoutubePlayerModule} from 'ngx-youtube-player';
import {
  VNxtAuthModule,
  VNxtAuthService,
  VNxtCanActivateChildGuard,
  VNxtCanActivateGuard,
  VNxtCanLoadGuard,
  VNxtRouteService
} from "@vnxt/angular-auth";
import {IAuthEnvironmentOptions} from "@vnxt/angular-auth/lib/interface/common";
import {PerformanceMonitoringService} from '@angular/fire/compat/performance';
import {ScreenTrackingService, UserTrackingService} from '@angular/fire/compat/analytics';
import {environment} from "../../environments/environment";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ServiceWorkerModule} from '@angular/service-worker';
import {AppComponent} from "@app/app.component";

@NgModule({
  declarations: [
    AppComponent,
    ContentLayoutComponent,
    NavComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    VNxtAuthModule.initializeAppModule(environment as IAuthEnvironmentOptions),
    AppRoutingModule,
    SlickCarouselModule,
    NgxYoutubePlayerModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.isCloudDeployment,
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    PerformanceMonitoringService,
    VNxtRouteService,
    VNxtAuthService,
    VNxtCanLoadGuard,
    VNxtCanActivateGuard,
    VNxtCanActivateChildGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
